<template>
  <div class="services-page">
    <section class="header p-1" algin="center">
      <center>
        <img
          class="revivify-logo"
          height="50px"
          :src="require('@/assets/images/logo/new_logo.png')"
          alt="Revivify Logo"
        />
      </center>
    </section>
    <section class="services-hero-section">
      <div class="services-hero-img">
        <img
          :src="require('@/assets/images/banner/banner-service-bg.jpeg')"
          width="100%"
          height="60vh"
          alt="Revivify Logo"
          class="img-fluid"
        />
      </div> 
      <div class="services-hero-content p-5">
        <h1>IT STARTS WITH YOU</h1>
        <p>
          Sell or refurbish your designer pieces with Revivify 
          to play your part <br> in inverting the impact of fast fashion. 
        </p>
        <!-- <button class="btn btn-white"></button> -->
        <a class="btn btn-white text-black" href="/register">Get Started</a>
      </div>
    </section>
    <section class="p-5 pb-lg-10 service-card-section bg-section">
      <div class="row">
        <div class="col-lg-4 services-card" align="center">
          <div class="services-card__overlay">
            <img
              :src="require('@/assets/images/banner/service-circle-1.png')"
              alt=""
              class="img-fluid services-card__img"
            />
            <div class="nums">1</div>
          </div>
          <div class="services-card__content">
            <h3 class="main-sm-heading">Open your seller account</h3>
            <p>
              Your seller account is an all-in-one space for managing and keeping an eye on your listings. See how much you've made, track your potential earnings, or even opt to give your items better visibility. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 services-card">
          <div class="services-card__overlay">
            <img
              :src="require('@/assets/images/banner/service-circle-1.png')"
              alt=""
              class="img-fluid services-card__img"
            />
            <div class="nums">2</div>
          </div>
          <div class="services-card__content">
            <h3 class="main-sm-heading">Submit your piece</h3>
            <p>
              Upload your listing by filling in the short online form and submitting it to our team. To support our expert, 2-step authentication process, we ask t hat you attach a selection of clear images. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 services-card">
          <div class="services-card__overlay">
            <img
              :src="require('@/assets/images/banner/service-circle-1.png')"
              alt=""
              class="img-fluid services-card__img"
            />
            <div class="nums">3</div>
          </div>
          <div class="services-card__content">
            <h3 class="main-sm-heading">Start selling and earning</h3>
            <p>
              Once your items have been approved, your Revivify journey begins! Our team will handle the whole process from beginning to end, including professional photography, buyer communication, and delivery. 
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="pb-lg-5">
      <div class="services-hero-section">
        <div class="services-hero-img">
          <img
            :src="require('@/assets/images/banner/banner-service.jpeg')"
            width="100%"
            alt="Revivify Logo"
            class="img-fluid"
          />
        </div>
        <div class="services-hero-content services-sm-hero-content">
          <h1>Resell. Refurb. Revive </h1>
          <p>
            At Revivify, we help you to refresh your wardrobe by either selling or breathing new life into the clothes and accessories you no longer wear. By combining our white-glove service with expert authentication and fashion know-how, we make doing your bit for the environment smooth, simple, and economically rewarding. 
          </p>
          <!-- <button class="btn btn-white">Start Selling</button> -->
          <a class="btn btn-white text-black" href="/register">Start Selling</a>
        </div>
      </div>
    </section>
    <section class="p-5 pb-lg-5 service-card-section">
      <div class="row justify-content-center text-center mb-5">
        <div class="col-lg-6">
          <h1 class="main-heading">Why choose Revivify? </h1>
          <p class="main-text">
            With Revivify, you can be assured of a seamless service from start to finish. From swift payments to expert seamstresses, we sweat the small details – so you don't have to.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 services-card">
          <div class="">
            <img
              :src="require('@/assets/images/banner/service-circle-1.png')"
              alt=""
              class="img-fluid services-card__img"
            />
          </div>
          <div class="services-card__content">
            <h3 class="main-sm-heading">STRAIGHTFORWARD SELLING </h3>
            <p>
             Selling with Revivify is designed to be a stress-free experience, which is why we handle the entire sale process from end to end. Our dedicated team, together with our unique seller platform and dashboard, do all of the legwork, meaning you can get back to the things that matter most.  
            </p>
          </div>
        </div>
        <div class="col-lg-4 services-card">
          <div class="">
            <img
              :src="require('@/assets/images/banner/service-circle-1.png')"
              alt=""
              class="img-fluid services-card__img"
            />
          </div>
          <div class="services-card__content">
            <h3 class="main-sm-heading">INDUSTRY LEADING RESULTS </h3>
            <p>
              Our experience allows us to price and position your item for the highest sale. By harnessing innovative technology, we make sure our listings are seen by the right people, at the right time, and showcased to convert. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 services-card">
          <div class="">
            <img
              :src="require('@/assets/images/banner/service-circle-1.png')"
              alt=""
              class="img-fluid services-card__img"
            />
          </div>
          <div class="services-card__content">
            <h3 class="main-sm-heading">THE UTMOST DISCRETION </h3>
            <p>
              Our sellers' confidentiality is important to us, so you can be assured your personal details and connection with the pieces you list will remain undisclosed. This is of course unless you wish to promote your listing on social media.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="p-5 pb-lg-5 service-card-section bg-section">
      <div class="row">
        <div class="col-lg-5 mt-5">
          <h1 class="main-heading">Be inspired</h1>
          <p class="text-wrap">
           Not sure what to sell? Browse our current listings to see what you could get for your pre-loved designer pieces.
          </p>
          <button class="btn btn-transparent">I'm Curious!</button>
        </div>
        <div class="col-lg-7">
          <img
            :src="require('@/assets/images/banner/service-circle-2.png')"
            width="100%"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>
    </section>
    <section class="my-5 pb-lg-5 services-faq">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h2 class="main-sm-heading">TOP SELLER QUESTIONS</h2>
          <!-- types -->
          <app-collapse accordion type="border">
            <app-collapse-item title="Accordion Item 1">
              Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant.
              Tootsie roll sweet candy bear claw chupa chups lollipop toffee.
              Macaroon donut liquorice powder candy carrot cake macaroon
              fruitcake. Cookie toffee lollipop cotton candy ice cream dragée
              soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart.
              Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé
              icing caramels. Chocolate cake icing ice cream macaroon pie
              cheesecake liquorice apple pie.
            </app-collapse-item>

            <app-collapse-item title="Accordion Item 2">
              Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant.
              Tootsie roll sweet candy bear claw chupa chups lollipop toffee.
              Macaroon donut liquorice powder candy carrot cake macaroon
              fruitcake. Cookie toffee lollipop cotton candy ice cream dragée
              soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart.
              Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé
              icing caramels. Chocolate cake icing ice cream macaroon pie
              cheesecake liquorice apple pie.
            </app-collapse-item>

            <app-collapse-item title="Accordion Item 3">
              Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant.
              Tootsie roll sweet candy bear claw chupa chups lollipop toffee.
              Macaroon donut liquorice powder candy carrot cake macaroon
              fruitcake. Cookie toffee lollipop cotton candy ice cream dragée
              soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart.
              Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé
              icing caramels. Chocolate cake icing ice cream macaroon pie
              cheesecake liquorice apple pie.
            </app-collapse-item>

            <app-collapse-item title="Accordion Item 4">
              Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant.
              Tootsie roll sweet candy bear claw chupa chups lollipop toffee.
              Macaroon donut liquorice powder candy carrot cake macaroon
              fruitcake. Cookie toffee lollipop cotton candy ice cream dragée
              soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart.
              Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé
              icing caramels. Chocolate cake icing ice cream macaroon pie
              cheesecake liquorice apple pie.
            </app-collapse-item>
          </app-collapse>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
  },
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.nums{
  font-family: 'Inter', sans-serif !important;
}

.services-page h1,
.services-page h3,
.services-page p {
  margin-bottom: 0px !important;
}
.services-page p {
  font-size: 1.2rem;
  line-height: 25px;
  color: #000 !important;
  letter-spacing: 0.5px;
}
.services-page .main-heading {
  color: #000 !important;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 20px;
  font-size: 32px;
}
.services-page .main-sm-heading {
  color: #000 !important;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 20px;
}
.services-page .btn-transparent {
  font-size: 16px;
  color: #000 !important;
  border-bottom: 1px solid #000;
  border-radius: 0px;
  padding: 0.5rem 0;
}

.services-hero-section {
  background: #000;
  position: relative;
}
.services-hero-img {
  opacity: 0.7;
}
.services-hero-img img {
  min-height: 80vh;
}
.services-hero-content {
  position: absolute;
  color: #fff !important;
  left: 1rem;
  top: 25%;
}
.services-sm-hero-content {
  color: #fff !important;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}
.services-hero-content h1 {
  color: #fff;
  padding-bottom: 20px;
  font-size: 42px;
  letter-spacing: 2px;
  font-weight: 600;
}
.services-hero-content p {
  color: #fff !important;
  padding-bottom: 25px;
}
.services-hero-content .btn-white {
  background: white;
  border-radius: 0px;
  color: #000;
  padding: 1rem 1.8rem;
  font-size: 1.2rem;
}
.services-card {
  color: #000;
  text-align: center;
}
.services-card__img {
  border-radius: 50%;
}
.services-card__overlay {
  background: #000;
  position: relative;
  border-radius: 50%;
  /* width: 350px;
  height: 350px; */
}
.services-card__overlay img {
  opacity: 0.3;
}
.services-card__overlay > div {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 150px;
}
.services-card__content {
  padding-top: 30px;
}

.services-faq .card-header {
  background: #f8f4ee !important;
  box-shadow: none;
}
.services-faq .card {
  box-shadow: none;
  background: #f8f4ee !important;
  border-bottom: 1px solid #F0EBE3 !important;
  border-top: 1px solid #F0EBE3 !important;
  color: #000000 !important;
}
.service-card-section{
  padding-left: 1rem;
  padding-right: 1rem;
}
.text-wrap{
  width: 450px;
  padding-bottom: 20px;
}
.bg-section{
  background: #fafafa;
}
.pb-lg-10{
  padding-bottom: 8rem;
}
@media (min-width: 1400px) {
  .services-hero-content {
    width: 50%;
  }
  .services-sm-hero-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>